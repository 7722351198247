
export const segmentTwoColors = {
    '0%': '#108ee9',
    '100%': '#87d068',
};

export const symbolMap = {
    'USD': '$',
    'EUR': '€',
    'UAH': '₴'
};

export const topUpCoins = [{
    name: 'USDT',
}, {
    name: 'TRX',
}, {
    name: 'ETH',
}, {
    name: 'ADA',
}, {
    name: 'BTC',
    disabled: true
}, {
    name: 'XMR',
    disabled: true
}];

export const withdrawCoins = [{
    name: 'USDT',
}, {
    name: 'USDC',
    disabled: true
}];

export const networkBlockchain = [{
  value: 'Tron',
  label: `Tron (TRC20)`,
}, {
  value: 'Ethereum',
  label: `Ethereum (ERC20)`,
}];

export const networkProtocol = [{
    value: 'TRC20',
    label: `Tron (TRC20)`,
}, {
    value: 'ERC20',
    label: `Ethereum (ERC20)`,
}];

export const chains = [
    {
        value: 'Tron',
        label: 'Tron',
    },
    {
        value: 'Ethereum',
        label: 'Ethereum',
    },
];

export const protocols = {
    Ethereum: [
        {
            value: 'ERC20',
            label: 'ERC20',
        }
    ],
    Tron: [
        {
            value: 'TRC20',
            label: 'TRC20',
        }
    ]
}
