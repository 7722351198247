import { Modal } from 'antd';
import AddressCreateForm from './components/Form';
import { useWithdrawalAddressMutation } from '../../hooks';

export function AddressCreate(props) {
  const { open, onClose, page, callback } = props;

  const mutation = useWithdrawalAddressMutation({ page, callback });

  const onFormSubmit = (values) => {
    mutation.create.mutate(values);
  };

  return (
  <Modal
    className="cjaaar-modal"
    destroyOnClose={true}
    open={open}
    onCancel={onClose}
    maskClosable={false}
    footer={null}
  >
    <AddressCreateForm
      submitting={mutation.create.isPending}
      onFormSubmit={onFormSubmit}
    />
  </Modal>
  )
}
