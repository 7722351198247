import React from 'react';
import { Empty } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export default function Loading () {
  return <Empty
    style={{
      marginTop: 100
    }}
    image={<LoadingOutlined
      style={{
        color: '#1890ff', // TODO: color primary
        fontSize: '10rem',
      }}
    />}
    imageStyle={{
      height: 60,
    }}
    description={''}
  />
}
