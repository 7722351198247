import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, App, Modal, Segmented, Select, Checkbox, Input, Form } from 'antd';
import { LoadingOutlined, RocketOutlined, SearchOutlined } from '@ant-design/icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import http from '../../http';
import { networkBlockchain, withdrawCoins } from '../../../../../helpers/constants';
import { getSegmentLabel } from '../../../../jar/helpers/utils';
import * as utils from '../../../../../helpers/utils';
import logoMap from "../../../../../images";
import { BLOCKCHAIN_TOKENS_ADDRESS_REGEXP } from '../../../../../const';
import { SearchInput as AddressBookSearch } from '../../../addressbook/export';

export default function JarWithdrawalDrawer(props) {
  const { data, onClose, open } = props;

  const { t } = useTranslation('jars');

  const { notification } = App.useApp();

  const queryClient = useQueryClient();

  const [ showSearch, setShowSearch ] = React.useState(false);

  const jarFeeQuery = useQuery({
    queryKey: ['jar', 'fee', data?.id],
    queryFn: () => data?.id ? http.fee(data?.id) : null,
    refetchOnWindowFocus: false,
  });

  const mutation = useMutation({
    mutationFn: http.withdrawal,
    onSuccess: () => {
      notification.success({
        message: t('withdrawal.notifications.success')
      });

      queryClient.invalidateQueries({ queryKey: ['jars'] });

      onClose();
    },
    onError: () => {
      notification.error({
        message: t('withdrawal.notifications.error')
      });
    }
  });

  const [network, setNetwork] = React.useState('Tron');

  const feeAmount = jarFeeQuery.data && data ? jarFeeQuery.data[network][data.currency] : 0;
  const balance = utils.getBalance(data);
  const withdrawalAmount = balance - feeAmount;

  const initialValues = {
    amountAsset: 'USDT',
    network,
    address: '',
    closeJar: true
  }

  const showConfirm = (payload) => {
    Modal.confirm({
      title: t('withdrawal.confirmation.title'),
      icon: <RocketOutlined />,
      content: (
        <div>
          <div className="margin-bottom-16">
            {t('withdrawal.confirmation.messageStart', {
              balance: utils.formatCurrency(data?.currency, balance),
              feeAmount: utils.formatCurrency(data?.currency, feeAmount)
            })} <b>{utils.formatCurrency(data?.currency, withdrawalAmount)}</b> {t('withdrawal.confirmation.messageEnd')}
          </div>
          <div>{t('withdrawal.confirmation.coin')}: {payload.data.amountAsset}</div>
          <div className="margin-bottom-16">{t('withdrawal.confirmation.network')}: {payload.data.network}</div>
          <div className="margin-bottom-10">{t('withdrawal.confirmation.yourAddress')}: <b>{payload.data.address}</b></div>
        </div>
      ),
      onOk() {
        return mutation.mutate(payload);
      },
      okText: t('withdrawal.confirmation.okText'),
      cancelText: t('withdrawal.confirmation.cancelText'),
      onCancel() {},
    });
  };

  const onFormSubmit = (values) => {
    showConfirm({
      id: data?.id,
      data: {
        ...values,
        amountDisplayed: balance,
        amountCurrency: data?.currency,
        amountRequested: 0
      }
    });
  }

  return (
    <Modal
      className="cjaaar-modal"
      destroyOnClose
      open={open}
      maskClosable={false}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>{t('buttons.cancel')}</Button>,
        <Button
          key="submit"
          form="withdrawal-form"
          type="primary"
          htmlType="submit"
          loading={mutation.isLoading}
        >
          {t('buttons.withdraw')}
        </Button>
      ]}
    >
      <div>
        <div className="title">{t('withdrawal.title')}</div>
        <div className="jar-details margin-bottom-16">
          <div style={{ textAlign: 'center', color: '#a0a0a0' }} >{t('withdrawal.hint')}</div>
        </div>
        <div className="margin-bottom-16 text-align-center">
          <span className="jar-amount-collected">{utils.formatCurrency(data?.currency, balance)}</span>
          <span style={{ paddingRight: 0 }} className="jar-amount-of">- {t('withdrawal.feeText')} (</span>
          <span className="jar-amount-fee">{
              jarFeeQuery.isFetched
                ? utils.formatCurrency(data?.currency, feeAmount)
                : <LoadingOutlined/>
            }</span>
          <span style={{ paddingLeft: 0 }} className="jar-amount-of">) =</span>
          <span style={{ fontSize: '22px' }}
            className="jar-amount-target">{utils.formatCurrency(data?.currency, withdrawalAmount)}</span>
        </div>

        <Form
          id="withdrawal-form"
          onFinish={onFormSubmit}
          layout="vertical"
          initialValues={initialValues}
          onValuesChange={(changedValues) => {
            if (changedValues.network) {
              setNetwork(changedValues.network);
            }
          }}
        >
          <Form.Item
            name="amountAsset"
            label={t('withdrawal.form.fields.coins.label')}
            rules={[
              {
                required: true,
                message: t('manageForm.validations.required')
              }
            ]}
          >
            <Segmented
              block
              options={withdrawCoins.map((c) => {
                return {
                  label: getSegmentLabel(logoMap, c.name),
                  value: c.name,
                  disabled: c.disabled
                }
              })}
            />
          </Form.Item>
          <Form.Item
            name="network"
            label={t('withdrawal.form.fields.networks.label')}
            rules={[
              {
                required: true,
                message: t('manageForm.validations.required')
              }
            ]}
          >
            <Select
              style={{ width: '100%' }}
              options={networkBlockchain}
            />
          </Form.Item>
          <Form.Item noStyle shouldUpdate={(prev, curr) => prev.network !== curr.network}>
            {({ getFieldValue }) => {
              const network = getFieldValue('network');
              const label = t('withdrawal.form.fields.address.label', { asset: getFieldValue('asset'), network });
              return (
                <Form.Item
                  name="address"
                  label={label}
                  rules={[
                    {
                      required: true,
                      message: t('manageForm.validations.required')
                    },
                    {
                      pattern: BLOCKCHAIN_TOKENS_ADDRESS_REGEXP[network],
                      message: t('withdrawal.form.fields.address.validations.pattern')
                    }
                  ]}
                >
                  <Input placeholder={t('withdrawal.form.fields.address.placeholder')}
                         suffix={<SearchOutlined onClick={() => {
                           setShowSearch(!showSearch);
                         }} />}
                  />
                </Form.Item>
              )
            }}
          </Form.Item>
          {
            showSearch
              ? <Form.Item label={t('withdrawal.form.fields.addressbook.title')} shouldUpdate>
                {({ setFieldValue }) => <AddressBookSearch
                  onChange={({value}) => {
                    setFieldValue('address', value);
                    setShowSearch(false);
                  }}
                />}
              </Form.Item>
              : null
          }
          <Form.Item
            name="closeJar"
            valuePropName="checked"
            className="noselect"
          >
            <Checkbox>{t('withdrawal.form.fields.closeJar.label')} <small>{t('withdrawal.form.fields.closeJar.hint')}</small></Checkbox>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};
