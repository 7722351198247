import React, { useState } from 'react';
import { Col, Row, Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Sider from './components/Sider';

export default function LayoutPage () {
  const [showSidebar, setShowSidebar] = useState(false);

  return (
    <Layout id="cjaaar-layout">
      <Header
        showSidebar={showSidebar}
        setShowSidebar={setShowSidebar}
      />

      <Layout className="transparent">
        <Sider
          showSidebar={showSidebar}
          setShowSidebar={setShowSidebar}
        />
        <Layout.Content>
          <Row className="content-row full-height">
            <Col md={0} lg={1} xl={1}></Col>
            <Col xs={24} sm={24} md={24} lg={22} xl={22}>
              <div className="panel-general panel-dark panel-radius">
                <Outlet/>
              </div>
            </Col>
            <Col md={0} lg={1} xl={1}></Col>
          </Row>
        </Layout.Content>

      </Layout>
      <Footer/>
    </Layout>
  );
};
