import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import http from './http';
import { App } from 'antd';
import { useTranslation } from 'react-i18next';

export const useWithdrawalAddresses = ({ page }) => {
  const queryKey = ['withdrawal-addresses', page];

  const list = useQuery({
    queryKey,
    queryFn: () => http.list({
      page,
    }),
    refetchOnWindowFocus: false,
  });

  return list;
};

export const useWithdrawalAddressesSearch = (params) => {
  const queryKey = ['withdrawal-addresses', 'search', params];

  const search = useQuery({
    queryKey,
    queryFn: () => http.list(params),
    refetchOnWindowFocus: false,
  });

  return search;
};

export const useWithdrawalAddress = (id) => {
  const queryKey = ['withdrawal-address', id];

  const get = useQuery({
    queryKey,
    queryFn: () => id ? http.get(id) : null,
    refetchOnWindowFocus: false,
  });

  return get;
};

export const useWithdrawalAddressMutation = ({ page, callback }) => {
  const { notification } = App.useApp();

  const { t } = useTranslation('addressbook');

  const queryClient = useQueryClient();

  const queryKey = ['withdrawal-addresses', page];

  const create = useMutation({
    mutationFn: http.create,
    onSuccess: (record) => {
      if (page !== 1) {
        return callback();
      }

      queryClient.setQueryData(queryKey, (prev) => {
        const newData = [
          record,
          ...prev.data,
        ];

        if (newData.length > 5) {
          newData.splice(-1, 1);
        }

        const newMeta = {
          ...prev.meta,
          total: prev.meta.total + 1,
        };

        return {
          data: newData,
          meta: newMeta,
        };
      });

      notification.open({
        message: t('create.notifications.success'),
        type: 'success'
      });
    },
    onError: () => {
      notification.open({
        message: t('create.notifications.error'),
        type: 'error'
      });
    }
  });

  const update = useMutation({
    mutationFn: http.update,
    onSuccess: (record) => {
      queryClient.setQueryData(queryKey, (prev) => {
        const newData = prev.data.map((item) => {
          if (item.id === record.id) {
            return record;
          }

          return item;
        });

        return {
          data: newData,
          meta: prev.meta,
        };
      });

      notification.open({
        message: t('update.notifications.success'),
        type: 'success'
      });
    },
    onError: () => {
      notification.open({
        message: t('update.notifications.error'),
        type: 'error'
      });
    }
  });

  const remove = useMutation({
    mutationFn: http.remove,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey
      });

      notification.open({
        message: t('remove.notifications.success'),
        type: 'success'
      });
    },
    onError: () => {
      notification.open({
        message: t('remove.notifications.error'),
        type: 'error'
      });
    }
  });

  return {
    create,
    update,
    remove
  };
}
