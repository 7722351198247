import { Modal } from 'antd';
import AddressUpdateForm from './components/Form';
import { useWithdrawalAddress, useWithdrawalAddressMutation } from '../../hooks';

export function AddressUpdate(props) {
  const { page, onClose, id } = props;

  const address = useWithdrawalAddress(id);

  const mutation = useWithdrawalAddressMutation({
    page
  });

  const onFormSubmit = (values) => {
    mutation.update.mutate({
      id,
      values
    });
  };

  return (
    <Modal
      className="cjaaar-modal"
      destroyOnClose={true}
      open={!!id}
      onCancel={onClose}
      maskClosable={false}
      footer={null}
    >
      <AddressUpdateForm
        fetching={address.isFetching}
        submitting={mutation.update.isPending}
        initialValues={address.data}
        onFormSubmit={onFormSubmit}
      />
    </Modal>
  );
}
