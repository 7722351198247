import React from 'react';
import { Row, Timeline } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../../providers/auth';

export default function SettingsSecurityPage() {
  const auth = useAuth();

  const { t } = useTranslation('settings');

  return (
    <div>
      <div className="panel-title margin-bottom-16">{t('security.title')}</div>
      <Row gutter={[10, 10]}>
        <Timeline
          items={auth.user.sessions.map((session) => ({
            children: (
              <>
                <p>{new Intl.DateTimeFormat(undefined, {
                  dateStyle: 'medium',
                  timeStyle: 'short'
                }).format(new Date(session.createdAt))}
                </p>
                <p>IP: {session.ipAddress}</p>
                <p>User Agent: {session.userAgent}</p>
                <p>Provider: {session.provider}</p>
              </>
            )
          }))}
        />
      </Row>
    </div>
  )
}
