import { symbolMap } from './constants';

export const getUserLanguage = () => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
        return savedLanguage;
    }

    const browserLanguages = navigator.languages || ['en'];
    if (browserLanguages.includes('uk')) {
        return saveUserLanguage('ua');
    }

    return saveUserLanguage('en');
}

export const saveUserLanguage = (language) => {
    localStorage.setItem('language', language);
    return language;
}

export const formatCurrency = (currency, value, addSymbol = true) => {
    const formal = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        currency: currency,
    }).format(value)

    if (!addSymbol) {
        return formal
    }

    const symbol = getCurrencySymbol(currency);
    if (symbol === '$') {
        return `${symbol}${formal}`
    }

    return `${formal} ${symbol}`
}

export const getCurrencySymbol = (currency) => {
    return symbolMap[currency] || currency;
}

export const getBalance = (data) => {
    return data?.balance.fiat[data?.currency] || 0
}

export const getBalanceProgress = (data) => {
    let val = (getBalance(data) / data?.amount) * 100

    // TODO: TBD
    val = val > 100 ? 100 : val

    return val
}

export const normFile = (e, field = 'fileList') => Array.isArray(e) ? e : (e?.[field] || []);
