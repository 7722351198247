import React from 'react';
import { Button, Col, Grid, Row, Table } from 'antd';
import { AddressCreate } from './components/AddressCreate';
import { AddressUpdate } from './components/AddressUpdate';
import { useWithdrawalAddresses, useWithdrawalAddressMutation } from './hooks';
import { useTranslation } from 'react-i18next';

export default function AddressBookPage() {
  const screens = Grid.useBreakpoint();

  const { t } = useTranslation('addressbook');

  const [openCreate, setOpenCreate] = React.useState(false);

  const [addressId, setAddressId] = React.useState(null);

  const [page, setPage] = React.useState(1);

  const addresses = useWithdrawalAddresses({ page });

  const { remove } = useWithdrawalAddressMutation({ page });

  const columns = [
    { title: t('table.columns.chain'), dataIndex: 'blockchain', key: 'blockchain' },
    { title: t('table.columns.protocol'), dataIndex: 'protocol', key: 'protocol' },
    { title: t('table.columns.address'), dataIndex: 'address', key: 'address' },
    { title: t('table.columns.comment'), dataIndex: 'comment', key: 'comment' },
    {
      title: t('table.columns.actions'),
      key: 'change',
      render: (record) => {
        return (
          <>
            <Button type="link" onClick={() => setAddressId(record.id)}>
              {t('table.actions.update')}
            </Button>
            <Button type="link" danger onClick={() => {
              remove.mutate(record.id);
            }}>
              {t('table.actions.remove')}
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <div>
      <Row justify="space-between" align="middle">
        <Col xs={24} sm={6} md={8} align="left">
          <div className="panel-title">
            {t('title')}
          </div>
        </Col>
        <Col xs={24} sm={4} md={4} align="right">
          <Button block={screens.xs} style={{ marginRight: 'auto' }} type="primary" onClick={() => setOpenCreate(true)}>
            {t('buttons.create')}
          </Button>
        </Col>
      </Row>
      <Table
        id="address-book"
        rowKey="id"
        scroll={{ x: true }}
        loading={addresses.isFetching}
        columns={columns}
        locale={{
          emptyText: t('table.empty')
        }}
        dataSource={addresses.data?.data || []}
        pagination={{
          current: page,
          pageSize: 5,
          total: addresses.data?.meta.total || 1,
          onChange: (value) => setPage(value),
          position: ['topLeft']
        }}
      />
      <AddressCreate
        page={page}
        open={openCreate}
        callback={() => setPage(1)}
        onClose={() => setOpenCreate(false)}
      />
      <AddressUpdate
        page={page}
        id={addressId}
        onClose={() => setAddressId(null)}
      />
    </div>
  );
}
