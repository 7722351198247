import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Layout } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import LanguageSelect from '../LanguageSelect';

import logoImage from '../../../../images/logo.png';

import React from "react";

export default function Header({ isLanding = false, to = '/login', setShowSidebar, showSidebar }) {
    const { t } = useTranslation(['layout', 'landing']);

    return (
        <Layout.Header id="cjaaar-header" className={`${isLanding ? 'left on-landing' : ''}`}>
          <div className="logo-block" onClick={() => window.location.href = '/'}>
            <img className="header-logo" alt="CJAAR" src={logoImage} />
            <span className="header-text">CJAAAR</span>
            <small className="header-subtext">{t('header.logoSubText', { ns: 'layout' })}</small>
          </div>
          {
            isLanding ?
              <div>
                <Link to={to}>
                  <Button style={{ marginRight: '20px' }} type="primary">{t('buttonGetStarted', { ns: 'landing' })}</Button>
                </Link>
                <LanguageSelect />
              </div>
              : null
          }
          {
            setShowSidebar ?
              <div className="mobile-sider-button">
                <Button
                  type="text"
                  icon={<MenuOutlined style={{ fontSize: '25px' }}/>}
                  onClick={() => setShowSidebar(!showSidebar)}
                />
              </div>
              : null
          }
        </Layout.Header>
    )
}
