import config from '../../../config';
import { HTTP_CREDENTIALS, HTTP_HEADERS, HTTP_METHOD } from '../../../const';

const baseUrl = `${config.webUrl}/api/withdrawal-addresses`;

const jsonOrError = (res) => {
  if (res.ok) {
    return res.json();
  }

  throw new Error(res.responseText);
};

const list = (params) => {
  const url = new URL(baseUrl);

  if (params) {
    url.search = new URLSearchParams(params).toString();
  }

  return fetch(
    url, {
      method: HTTP_METHOD.GET,
      headers: HTTP_HEADERS,
      credentials: HTTP_CREDENTIALS
    }
  ).then(jsonOrError)
};

const get = (id) => {
  const url = new URL(baseUrl);

  url.pathname += `/${id}`;

  return fetch(
    url, {
      method: HTTP_METHOD.GET,
      headers: HTTP_HEADERS,
      credentials: HTTP_CREDENTIALS
    }
  ).then(jsonOrError)
};

const create = (values) => {
  const url = new URL(baseUrl);

  return fetch(
    url, {
      method: HTTP_METHOD.POST,
      headers: HTTP_HEADERS,
      credentials: HTTP_CREDENTIALS,
      body: JSON.stringify(values),
    }
  ).then(jsonOrError)
};

const update = ({id, values}) => {
  const url = new URL(baseUrl);

  url.pathname += `/${id}`;

  return fetch(
    url, {
      method: HTTP_METHOD.PUT,
      headers: HTTP_HEADERS,
      credentials: HTTP_CREDENTIALS,
      body: JSON.stringify(values),
    }
  ).then(jsonOrError)
};

const remove = (id) => {
  const url = new URL(baseUrl);

  url.pathname += `/${id}`;

  return fetch(
    url, {
      method: HTTP_METHOD.DELETE,
      headers: HTTP_HEADERS,
      credentials: HTTP_CREDENTIALS
    }
  ).then(jsonOrError)
};


const http = {
  list,
  get,
  create,
  update,
  remove,
};

export default http;
