import { Button, Layout, Space, Row, Col, Collapse } from 'antd';
import { ArrowRightOutlined, RocketOutlined, ArrowDownOutlined, DollarOutlined, GlobalOutlined, SmileOutlined } from "@ant-design/icons";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Header from '../../components/Layout/components/Header';
import Footer from '../../components/Layout/components/Footer';

import secure from '../../images/secure.jpg';
import transparent from '../../images/transparent.jpg';
import efficient from '../../images/eff.jpg';
import { useAuth } from '../../providers/auth';

function FAQSection() {
  const { t } = useTranslation('faq');
  const howCryptoWorks = t('howCryptoWorks', { returnObjects: true });

  return (
    <div className="section panel-dark">
      <div className="margin-bottom-50">
        <div className="title margin-bottom-25">{t('faqTitle')}</div>
      </div>
      <Collapse items={Object.values(howCryptoWorks).map((value, key) => {
        return {
          key,
          label: value.title,
          children: <p style={{ padding: '0 30px' }}>{value.description}</p>
        };
      })} bordered={false}/>
    </div>
  )
}

export default function Landing () {
  const { t } = useTranslation('landing');
  const auth = useAuth()

  const to = auth.user ? '/app/jars' : '/login';

  return (
    <Layout id="cjaaar-landing">
      <div className="custom-bg">
        <Header isLanding={true} to={to}/>
        <Layout.Content>
          <div className="section-main">
            <div className="text-align-center">
              <div style={{ marginBottom: '70px' }} className="title gradient-color margin-bottom-25">{t('mainTitle')}</div>
              <div style={{ marginBottom: '70px', fontWeight: 'bold' }} className="text">{t('mainSubTitle')}</div>
              <Space size="large">
                <a href="#learn-more">
                  <Button size="large">{t('buttonLearnMore')} <ArrowDownOutlined/></Button>
                </a>
                <Link to={to}>
                  <Button type="primary" size="large">{t('buttonGetStarted')} <ArrowRightOutlined/></Button>
                </Link>
              </Space>
            </div>
          </div>

        </Layout.Content>
      </div>
      <div id="learn-more" className="section">
        <div className="text-align-center margin-bottom-50">
          <div className="title margin-bottom-25">{t('empowerSection.title')}</div>
          <div className="text">{t('empowerSection.subTitle')}</div>
        </div>
        <Row>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <div className="info-block">
              <img src={secure} alt="knight"/>
              <div className="margin-bottom-16 text">{t('empowerSection.secureTitle')}</div>
              <div className="text-align-center">{t('empowerSection.secureText')}</div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <div className="info-block">
              <img src={transparent} alt="layers"/>
              <div className="margin-bottom-16 text">{t('empowerSection.transparentTitle')}</div>
              <div className="text-align-center">{t('empowerSection.transparentText')}</div>
            </div>

          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <div className="info-block">
              <img src={efficient} alt="layers"/>
              <div className="margin-bottom-16 text">{t('empowerSection.efficientTitle')}</div>
              <div className="text-align-center">{t('empowerSection.efficientText')}</div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="section panel-dark">
        <div className="left-title margin-bottom-50">
          <div className="title margin-bottom-25">{t('freedomSection.title')}</div>
        </div>
        <Row>
          <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
            <div className="info-block black">
              <div className="text-align-center icon" style={{ color: '#38db95' }}>
                <DollarOutlined/>
              </div>
              <div className="margin-bottom-16 text-align-center text">{t('freedomSection.feeTitle')}</div>
              <div className="text-align-center">{t('freedomSection.feeText')}</div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
            <div className="info-block black">
              <div className="text-align-center icon" style={{ color: '#feec0e' }}>
                <RocketOutlined />
              </div>
              <div className="margin-bottom-16 text-align-center text">{t('freedomSection.fastSecureTitle')}</div>
              <div className="text-align-center">{t('freedomSection.fastSecureText')}</div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
            <div className="info-block black">
              <div className="text-align-center icon">
                <GlobalOutlined/>
              </div>
              <div className="margin-bottom-16 text-align-center text">{t('freedomSection.globalTitle')}</div>
              <div className="text-align-center">{t('freedomSection.globalText')}</div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
            <div className="info-block black">
              <div className="text-align-center icon" style={{ color: '#17a9d6' }}>
                <SmileOutlined/>
              </div>
              <div className="margin-bottom-16 text-align-center text">{t('freedomSection.friendlyTitle')}</div>
              <div className="text-align-center">{t('freedomSection.friendlyText')}</div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="section">
        <div className="margin-bottom-50 text-align-center">
          <div className="title margin-bottom-25 gradient-color">{t('getStartedSection.title')}</div>
        </div>
        <Row>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <div className="info-block">
              <div className="step margin-bottom-16">1</div>
              <div className="margin-bottom-16 text-align-center text">{t('getStartedSection.createTitle')}</div>
              <div className="text-align-center">{t('getStartedSection.createText')}</div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <div className="info-block">
              <div className="step margin-bottom-16">2</div>
              <div className="margin-bottom-16 text-align-center text">{t('getStartedSection.shareTitle')}</div>
              <div className="text-align-center">{t('getStartedSection.shareText')}</div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <div className="info-block">
              <div className="step margin-bottom-16">3</div>
              <div className="margin-bottom-16 text-align-center text">{t('getStartedSection.trackTitle')}</div>
              <div className="text-align-center">{t('getStartedSection.trackText')}</div>
            </div>
          </Col>
        </Row>
      </div>
      <FAQSection />
      <Footer/>
    </Layout>
  )
}
