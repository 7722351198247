import { Select, Space } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { saveUserLanguage } from '../../../../helpers/utils';
import i18n from '../../../../i18n';
import profileHttp from '../../../../http/profile';
import { useAuth } from '../../../../providers/auth';

export default function LanguageSelect() {
  const auth = useAuth();
  const language = i18n.language;

  const mutation = useMutation({
    mutationFn: profileHttp.update,
    onSuccess: () => {
      window.location.reload();
    }
  });

  return (
    <Select
      popupMatchSelectWidth={false}
      placement="topLeft"
      variant="borderless"
      options={[
        {
          label: '🇺🇸 EN',
          value: 'en',
          emoji: '🇺🇸',
          desc: 'English',
        },
        {
          label: '🇺🇦 UA',
          value: 'ua',
          emoji: '🇺🇦',
          desc: 'Українська',
        }
      ]}
      value={language}
      optionLabelProp="label"
      optionRender={(option) => (
        <Space>
                    <span role="img" aria-label={option.data.label}>
                      {option.data.emoji}
                    </span>
          {option.data.desc}
        </Space>
      )}
      onChange={(value) => {
        saveUserLanguage(value);
        if (auth?.user) {
          mutation.mutate({
            language: value
          });
        } else {
          window.location.reload();
        }
      }}
    />
  );
}
