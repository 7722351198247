import { Fragment, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CopyOutlined, QuestionCircleOutlined, ScanOutlined, StarFilled, FireOutlined } from '@ant-design/icons';
import { Col, Avatar, Button, Card, Divider, message, Modal, Progress, QRCode, Segmented, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import * as utils from '../../../helpers/utils';
import { segmentTwoColors, networkProtocol, topUpCoins } from '../../../helpers/constants';

import { getAddressByToken, getSegmentLabel } from '../helpers/utils';

import logoMap from '../../../images/index';
import logoImage from '../../../images/logo.png';

export function LeftPanel({ data, token, setToken }) {
    const { t } = useTranslation('jar');
    const address = getAddressByToken(data, token.protocol);

    const balance = utils.getBalance(data);
    const balanceProgress = utils.getBalanceProgress(data);

    return (
        <Col xs={24} sm={24} md={24} lg={10} xl={8} xxl={8}>
            <div id="left-panel" className="panel-general">
                <div className="margin-bottom-16">
                    {data.user?.name ?
                        <div className="jar-creator-block">
                            <span className="jar-creator-name">
                                {data.user?.verified ?
                                    <StarFilled style={{ color: '#feec0e' }}/> : null} {data.user?.name}
                            </span>
                            <span className="jar-created-by">{t('accumulates')}</span>
                        </div>
                        : null
                    }
                    <div className="jar-name">{data.name}</div>
                </div>

                <JarDescription data={data} />

                <div className="margin-bottom-16 text-align-center">
                    {
                        balance !== 0 ?
                            <Fragment>
                                <Divider style={{ marginTop: 0 }}><FireOutlined/> {t('goal')}</Divider>
                                <div style={{ margin: '20px 0' }}>
                                    <span
                                        className="jar-amount-collected">{utils.formatCurrency(data.currency, balance)}</span>
                                    <span className="jar-amount-of">{t('accumulatesOf')}</span>
                                    <span
                                        className="jar-amount-target">{utils.formatCurrency(data.currency, data.amount)}</span>
                                </div>
                            </Fragment>
                            :
                            <Fragment>
                                <Divider style={{ marginTop: 0 }}><FireOutlined/> {t('goal')}</Divider>
                                <div style={{ margin: '20px 0' }}>
                                    <span
                                        className="jar-amount-collected">{utils.formatCurrency(data.currency, data.amount)}</span>
                                </div>
                            </Fragment>
                    }
                    {
                        balanceProgress >= 1 ?
                            <Progress size={['100%', 12]} showInfo={true} status="active" percent={balanceProgress}
                                      strokeColor={segmentTwoColors} format={(percent) => `${Math.round(percent)}%`}/>
                            : null
                    }
                </div>
                <div className="margin-bottom-16">
                    <div className="label">{t('coins')}</div>
                    <Segmented
                        block
                        defaultValue={token.name}
                        options={topUpCoins.map((c) => {
                            return {
                                label: getSegmentLabel(logoMap, c.name),
                                value: c.name,
                                disabled: c.disabled
                            }
                        })}
                        onChange={(value) => {
                            if (value === 'USDT') {
                                // by default take TRON network
                                setToken({ name: value, protocol: 'TRC20' })
                            } else {
                                setToken({ name: value, protocol: value })
                            }
                        }}
                    />
                </div>

                {
                    token.name === 'USDT' ?
                        <div className="margin-bottom-16">
                            <div className="label">{t('network')}</div>
                            <Select
                                defaultValue="TRC20"
                                style={{ width: '100%' }}
                                options={networkProtocol}
                                onChange={(value) => {
                                    setToken({ name: 'USDT', protocol: value })
                                }}
                            />
                        </div>
                        : null
                }
                <div className="margin-bottom-16">
                    <div className="label">{t('address', { coin: `${token.name}${token.name === 'USDT' ? ` (${token.protocol})` : ''}` })}</div>
                    <Card size="small" bordered={false}>
                        <div style={{ fontWeight: 'bold', fontSize: '16px', wordWrap: 'break-word' }}>{address}</div>
                    </Card>
                </div>

                <div>
                    <CopyToClipboard
                        text={address}
                        onCopy={() => message.success(t('copyMessage'))}
                    >
                        <Button style={{ width: '100%' }} type="primary" icon={<CopyOutlined />}>{t('copyAddressButton')}</Button>
                    </CopyToClipboard>
                </div>
            </div>
        </Col>
    )
}

export function RightPanel({ data, token }) {
    const { t } = useTranslation(['jar', 'faq']);
    const [isNextOpen, setIsNextOpen] = useState(false);
    const [isInfoOpen, setIsInfoOpen] = useState(false);
    const address = getAddressByToken(data, token.protocol);

    const nextThingsToDo = t('nextThingsToDo', { ns: 'faq', returnObjects: true });
    const howCryptoWorks = t('howCryptoWorks', { ns: 'faq', returnObjects: true });

    return (
        <Col xs={24} sm={24} md={24} lg={10} xl={8} xxl={8}>
            <div id="right-panel" className="panel-general">
                <div>
                    <Divider style={{ marginTop: 0 }} plain><ScanOutlined /> {t('qrCode', { ns: 'jar' })}</Divider>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <QRCode
                        errorLevel="H"
                        size={180}
                        value={address}
                        icon={logoMap[token.name]}
                        iconSize={32}
                        color='#000'
                        bgColor='#F7F7F7'
                    ></QRCode>
                </div>
                <Divider dashed />

                <JarDescription data={data} />

                <div className="how-it-works">
                    <div className="next-thing-to-do pointer" onClick={() => setIsNextOpen(true)}>
                        <QuestionCircleOutlined /> {t('nextThingToDo', { ns: 'jar' })}
                    </div>
                    <div className="pointer" onClick={() => setIsInfoOpen(true)}>
                        <QuestionCircleOutlined /> {t('howCryptoWorks', { ns: 'jar' })}
                    </div>
                </div>

                <Modal
                    className="info"
                    open={isNextOpen}
                    onCancel={() => setIsNextOpen(false)}
                    onOk={() => setIsNextOpen(false)}
                    width={700}
                    footer={null}
                >
                    <div>
                        <div style={{ marginTop: '20px' }} className="info-title margin-bottom-16 text-align-center">{nextThingsToDo.title}</div>
                        <div className="info-text margin-bottom-16">{nextThingsToDo.description}</div>
                        { Object.keys(nextThingsToDo.steps).map((segmentKey, key) => {
                            const data = nextThingsToDo.steps[segmentKey];
                            return (
                                <div key={`next-thing-${key}`}>
                                    <div className="info-title">{key + 1}) {data.title}</div>
                                    <div className="info-text">{data.description}</div>
                                    <Divider dashed />
                                </div>
                            )
                        }) }
                    </div>
                </Modal>

                <Modal
                    className="info"
                    open={isInfoOpen}
                    onCancel={() => setIsInfoOpen(false)}
                    onOk={() => setIsInfoOpen(false)}
                    width={700}
                    footer={null}
                >
                    <div>
                        <div className="info-title margin-bottom-16 text-align-center">{t('howCryptoWorks', { ns: 'jar' })}</div>
                        {Object.keys(howCryptoWorks).map((segmentKey, key) => {
                            const data = howCryptoWorks[ segmentKey ];
                            return (
                                <div key={`faq-${key}`}>
                                    <div className="info-title">{data.title}</div>
                                    <div className="info-text">{data.description}</div>
                                    <Divider dashed/>
                                </div>
                            )
                        })}
                    </div>
                </Modal>
            </div>
        </Col>
    )
}

function JarDescription({ data }) {
    return (
        <div className="jar-description">
            {
                data.description ?
                    <Card className="text-align-center">
                        {data.description}
                    </Card>
                    : null
            }

            <div style={{ position: 'absolute', zIndex: 999, bottom: '-55px', right: '50%', marginRight: '-40px' }}>
                <Avatar
                    className={`jar-avatar ${!data.image ? 'fit-cover' : ''}`}
                    size={80}
                    src={data.image ? data.image : logoImage}
                />
            </div>
        </div>
    )
}
