import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { App as AntApp, ConfigProvider, theme } from 'antd';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate
} from "react-router-dom";

import { AuthProvider, RequireAuth } from './providers/auth';
import Layout from './components/Layout';
import Login from './pages/login';
import Terms from './pages/legal/terms';
import Privacy from './pages/legal/privacy';
import Jar from './pages/jar';
import Landing from './pages/landing';

import Jars from './pages/app/jars';
import SettingsProfile from './pages/app/settings/components/profile';
import SettingsSecurity from './pages/app/settings/components/security';
import WithdrawalsPage from './pages/app/withdrawals';
import AddressBookPage from './pages/app/addressbook';

const queryClient = new QueryClient()

const router = createBrowserRouter([
  {
    path: "/",
    element: <AuthProvider redirectToLoginOnError={false}>
      <Landing/>
    </AuthProvider>,
  },
  {
    path: "/app",
    element: <AuthProvider redirectToLoginOnError={true}>
      <RequireAuth>
        <Layout/>
      </RequireAuth>
    </AuthProvider>,
    children: [
      { path: '', element: <Navigate to="jars" replace/> },
      { path: "jars", element: <Jars/> },
      { path: "withdrawals", element: <WithdrawalsPage/> },
      { path: "address-book", element: <AddressBookPage/> },
      {
        path: "settings",
        children: [
          { path: "profile", element: <SettingsProfile/> },
          { path: "security", element: <SettingsSecurity/> },
        ]
      }
    ],
  },
  {
    path: "jar/:uuid",
    element: <Jar/>,
  },
  {
    path: "login",
    element: <Login/>,
  },
  {
    path: "terms",
    element: <Terms/>,
  },
  {
    path: "privacy",
    element: <Privacy/>,
  }
]);

// TODO: temp disable theme
const themeConfig = {
  algorithm: theme.darkAlgorithm,
}

function App () {
  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider theme={themeConfig}>
        <AntApp>
          <RouterProvider router={router}/>
        </AntApp>
      </ConfigProvider>
    </QueryClientProvider>
  );
}

export default App;
