import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import settingsEN from './locales/en/settings.json';
import settingsUA from './locales/ua/settings.json';
import jarEN from './locales/en/jar.json';
import jarUA from './locales/ua/jar.json';
import faqEN from './locales/en/faq.json';
import faqUA from './locales/ua/faq.json';
import layoutEN from './locales/en/layout.json';
import layoutUA from './locales/ua/layout.json';
import legalEN from './locales/en/legal.json';
import legalUA from './locales/ua/legal.json';
import landingEN from './locales/en/landing.json';
import landingUA from './locales/ua/landing.json';
import jarsEN from './locales/en/jars.json';
import jarsUA from './locales/ua/jars.json';
import withdrawalEN from './locales/en/withdrawal.json';
import withdrawalUA from './locales/ua/withdrawal.json';
import addressBookEN from './locales/en/addressbook.json';
import addressBookUA from './locales/ua/addressbook.json';
import loginEN from './locales/en/login.json';
import loginUA from './locales/ua/login.json';

import { getUserLanguage } from './helpers/utils';

const resources = {
  en: {
    jar: jarEN,
    jars: jarsEN,
    settings: settingsEN,
    faq: faqEN,
    layout: layoutEN,
    legal: legalEN,
    landing: landingEN,
    withdrawal: withdrawalEN,
    addressbook: addressBookEN,
    login: loginEN
  },
  ua: {
    jar: jarUA,
    jars: jarsUA,
    settings: settingsUA,
    faq: faqUA,
    layout: layoutUA,
    legal: legalUA,
    landing: landingUA,
    withdrawal: withdrawalUA,
    addressbook: addressBookUA,
    login: loginUA
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    supportedLngs: ["en", "ua"],
    lng: getUserLanguage(),
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
