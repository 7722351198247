
export const getSegmentLabel = (logoMap, title) => {
    return (
        <div
            style={{
                padding: 4,
            }}
        >
            <img alt="Crypto coin" style={{ height: '30px' }} src={logoMap[title]} />
            <div>{title}</div>
        </div>
    );
}

export const getAddressByToken = (data, token) => {
    for (const wallet of data.wallets) {
        for (const t of wallet.blockchain.tokens) {
            if (t.protocol === token) {
                return wallet.address
            }
        }
    }
    return null;
}


