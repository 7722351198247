import { Button, Form, Input, Select, Skeleton } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { chains, protocols } from '../../../../../../../helpers/constants';

export default function AddressUpdateForm(props) {
  const { onFormSubmit, submitting, fetching, initialValues } = props;

  const { t } = useTranslation('addressbook');

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  return <>
    <div className="title margin-bottom-16">{t('update.title')}</div>
    <Form
      form={form}
      layout="vertical"
      onFinish={onFormSubmit}
      initialValues={initialValues}
      onValuesChange={(changedValues) => {
        if (changedValues.blockchain) {
          form.setFieldValue('protocol', protocols[changedValues.blockchain][0].value);
        }
      }}
    >
      <Form.Item name="blockchain" label={t('update.form.fields.chain.label')}>
        {fetching ? <Skeleton.Input block active/> : <Select
          style={{ width: '100%' }}
          options={chains}
        />}
      </Form.Item>
      {fetching ? <Skeleton.Input block active/> : <Form.Item noStyle shouldUpdate={(prev, curr) => prev.blockchain !== curr.blockchain}>
        {({ getFieldValue }) => {
          const blockchain = getFieldValue('blockchain');
          return <Form.Item name="protocol" label={t('update.form.fields.protocol.label')}>
            <Select
              style={{ width: '100%' }}
              options={protocols[blockchain]}
            />
          </Form.Item>
        }}
      </Form.Item>}
      <Form.Item name="address" label={t('update.form.fields.address.label')} rules={[
        {
          required: true,
          message: t('update.form.fields.address.validations.required')
        }
      ]}>
        {fetching ? <Skeleton.Input block active/> : <Input/>}
      </Form.Item>
      <Form.Item name="comment" label={t('update.form.fields.comment.label')}>
        {fetching ? <Skeleton.Input block active/> : <Input.TextArea/>}
      </Form.Item>
      <Form.Item>
        {fetching ? <Skeleton.Button active/> :
          <Button loading={submitting} type="primary" htmlType="submit">
            {t('update.form.buttons.update')}
          </Button>}
      </Form.Item>
    </Form>
    </>
    }
