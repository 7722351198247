import Icon from '@ant-design/icons';

const JarSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="currentColor"
    viewBox="0 0 491.864 491.864"
    xmlSpace="preserve"
  >
    <path
      d="M382.26 104.098V66.056H107.203v36.489c-28.701 18.426-43.242 48.144-43.242 88.441v187.37c0 75.322 52.133 113.508 154.948 113.508h54.044c102.81 0 154.95-38.186 154.95-113.508v-187.37c0-38.882-15.349-68.076-45.643-86.888m24.021 274.252c0 61.835-43.604 91.886-133.328 91.886h-54.044c-89.72 0-133.328-30.051-133.328-91.886V190.98c0-34.882 12.333-58.718 37.704-72.884l5.544-3.087V87.681h231.814v29.036l5.62 3.069c26.926 14.732 40.018 38.018 40.018 71.194zM100.45 35.135V14.416C100.45 6.458 106.899 0 114.86 0h262.15c7.959 0 14.413 6.452 14.413 14.416v20.719c0 7.955-6.454 14.416-14.413 14.416H114.86c-7.961 0-14.41-6.455-14.41-14.416m262.026 351.599c0 27.243-38.988 49.335-87.087 49.335-26.98 0-51.106-6.954-67.081-17.878 10.239-8.038 19.984-18.067 28.503-29.618 11.876-16.1 20.31-33.637 24.537-50.521 4.566-.42 9.255-.651 14.041-.651 48.098.011 87.087 22.09 87.087 49.333M139.79 420.273c-21.93-16.173-16.547-60.653 12.008-99.362 28.552-38.709 69.48-56.965 91.392-40.791 21.933 16.178 16.55 60.666-12.005 99.368-28.561 38.703-69.477 56.964-91.395 40.785"/>
  </svg>
)

export const JarIcon = (props) => <Icon component={JarSvg} {...props} />;
