import config from '../../../config';
import { HTTP_CREDENTIALS, HTTP_HEADERS, HTTP_METHOD } from '../../../const';

const baseUrl = `${config.webUrl}/api/withdrawals`;

const jsonOrError = (res) => {
  if (res.ok) {
    return res.json();
  }

  throw new Error(res.responseText);
};

const list = (params) => {
  const url = new URL(baseUrl);

  if (params) {
    url.search = new URLSearchParams(params).toString();
  }

  return fetch(
    url, {
      method: HTTP_METHOD.GET,
      headers: HTTP_HEADERS,
      credentials: HTTP_CREDENTIALS
    }
  ).then(jsonOrError)
};

const http = {
  list,
};

export default http;
