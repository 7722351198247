import { Button, Col, Layout, Row } from 'antd';
import { GoogleOutlined, TwitterOutlined, GithubOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Layout/components/Header';
import Footer from '../../components/Layout/components/Footer';
import config from '../../config';


export default function Login () {
  const { t } = useTranslation('login');
  return (
    <Layout id="cjaaar-layout" className="login">
      <Header/>
      <Layout.Content>
        <Row className="content-row" >
          <Col xs={0} sm={0} md={0} lg={12} xl={12} xxl={12}>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <div className="login-form">
              <div className="panel-general panel-radius" style={{ width: '100%' }}>
                <div className="panel-title text-align-center margin-bottom-10">{t('welcome')}</div>
                <div className="subtext text-align-center margin-bottom-16">{t('oauthMethods')}</div>
                <div className="margin-bottom-25 text-align-center">
                  <div className="margin-bottom-10">
                    <Button style={{ width: '200px' }} size="large" href={`${config.webUrl}/oauth/google/redirect`}><GoogleOutlined /> Google</Button>
                  </div>
                  <div className="margin-bottom-10">
                    <Button style={{ width: '200px' }} size="large" href={`${config.webUrl}/oauth/twitter/redirect`}><TwitterOutlined /> Twitter</Button>
                  </div>
                  <div className="margin-bottom-10">
                    <Button style={{ width: '200px' }} size="large" href={`${config.webUrl}/oauth/github/redirect`}><GithubOutlined /> Github</Button>
                  </div>
                </div>
                <div className="subtext text-align-center">{t('privacyPolicy.start')} <a href="/terms" target="_blank" className="link">{t('privacyPolicy.terms')}</a> {t('privacyPolicy.and')} <a href="/privacy" target="_blank" className="link">{t('privacyPolicy.privacy')}</a></div>
              </div>
            </div>
          </Col>
        </Row>
      </Layout.Content>
      <Footer />
    </Layout>
  )
}
