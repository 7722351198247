import { Button, Form, Input, Select } from 'antd';
import React from 'react';
import { protocols, chains } from '../../../../../../../helpers/constants';
import { useTranslation } from 'react-i18next';

export default function AddressCreateForm(props) {
  const { onFormSubmit, submitting } = props;

  const [ form ] = Form.useForm();

  const { t } = useTranslation('addressbook');

  return <>
    <div className="title margin-bottom-16">{t('create.title')}</div>
    <Form
      form={form}
      layout="vertical"
      onFinish={onFormSubmit}
      initialValues={{
        blockchain: 'Tron',
        protocol: 'TRC20',
      }}
      onValuesChange={(changedValues) => {
        if (changedValues.blockchain) {
          form.setFieldValue('protocol', protocols[changedValues.blockchain][0].value);
        }
      }}
    >
      <Form.Item name="blockchain" label={t('create.form.fields.chain.label')}>
        <Select
          style={{ width: '100%' }}
          options={chains}
        />
      </Form.Item>
      <Form.Item noStyle shouldUpdate={(prev, curr) => prev.blockchain !== curr.blockchain}>
        {({ getFieldValue }) => {
          const blockchain = getFieldValue('blockchain');
          return <Form.Item name="protocol" label={t('create.form.fields.protocol.label')}>
            <Select
              style={{ width: '100%' }}
              options={protocols[blockchain]}
            />
          </Form.Item>
        }}
      </Form.Item>
      <Form.Item name="address" label={t('create.form.fields.address.label')} rules={[
        {
          required: true,
          message: t('create.form.fields.address.validations.required')
        }
      ]}>
        <Input/>
      </Form.Item>
      <Form.Item name="comment" label={t('create.form.fields.comment.label')}>
        <Input.TextArea/>
      </Form.Item>
      <Form.Item>
        <Button loading={submitting} type="primary" htmlType="submit">
          {t('create.form.buttons.create')}
        </Button>
      </Form.Item>
    </Form>
    </>
}
