import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Modal } from 'antd';
import http from '../../http';

import ManageForm from './components/form';

export default function JarCreate (props) {
  const { open, onClose } = props;

  const { t } = useTranslation('jars');

  const { notification } = App.useApp();

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: http.create,
    onSuccess: () => {
      notification.success({
        message: t('create.notifications.success')
      });

      queryClient.invalidateQueries({ queryKey: ['jars'] });

      onClose();
    },
    onError: () => {
      notification.error({
        message: t('create.notifications.error')
      });
    }
  });

  const onFormSubmit = (values) => {
    const logo = values.imageType === 'customImage' ? values.logo[0]?.response?.url : '';
    mutation.mutate({
      ...values,
      type: 1,
      logo
    });
  };

  const initialValues = {
    currency: 'USD',
    amount: 100,
    name: 'Jar #1',
    description: '',
    imageType: 'profileImage',
    logo: []
  };

  return (
    <Modal
      className="cjaaar-modal"
      destroyOnClose={true}
      open={open}
      onCancel={onClose}
      maskClosable={false}
      footer={null}
    >
      <ManageForm
        initialValues={initialValues}
        onFormSubmit={onFormSubmit}
        title={t('create.title')}
        submitText={t('create.form.submitText')}
      />
    </Modal>
  );
};
