import usdtLogo from './tokens/usdt.svg';
import usdcLogo from './tokens/usdc.svg';
import ethLogo from './tokens/eth.svg';
import adaLogo from './tokens/ada.svg';
import tronLogo from './tokens/tron.svg';
import btcLogo from './tokens/bitcoin.svg';
import xmrLogo from './tokens/monero.svg';

const logoMap = {
    USDT: usdtLogo,
    USDC: usdcLogo,
    ETH: ethLogo,
    ADA: adaLogo,
    TRX: tronLogo,
    BTC: btcLogo,
    XMR: xmrLogo,
}

export default logoMap;
