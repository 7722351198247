import React from 'react';
import { Button, Col, Form, Input, InputNumber, Radio, Row, Upload, App } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import profileHttp from '../../../../../../http/profile';
import { normFile } from '../../../../../../helpers/utils';

export default function ManageForm(props) {
  const { initialValues, onFormSubmit, title, submitText } = props;
  const { t } = useTranslation('jars');
  const { notification } = App.useApp();

  return (
    <div>
      <div className="title margin-bottom-16">{title}</div>
      <Form
        onFinish={onFormSubmit}
        layout="vertical"
        initialValues={initialValues}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t('manageForm.validations.required')
                },
                {
                  type: 'string',
                  min: 3,
                  max: 50,
                  message: t('manageForm.fields.name.validations.length')
                }
              ]}
              label={t('manageForm.fields.name.label')} name="name">
              <Input placeholder={t('manageForm.fields.name.placeholder')} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t('manageForm.validations.required')
                },
                {
                  type: 'number',
                  min: 100,
                  message: t('manageForm.fields.amount.validations.min')
                }
              ]}
              label={t('manageForm.fields.amount.label')} name="amount">
              <InputNumber style={{ width: '100%' }} placeholder={t('manageForm.fields.amount.placeholder')} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[
                {
                  required: true
                },
                {
                  type: 'enum',
                  enum: ['USD', 'EUR', 'UAH']
                }
              ]}
              label={t('manageForm.fields.currency.label')} name="currency">
              <Radio.Group>
                <Radio.Button value="USD">USD</Radio.Button>
                <Radio.Button value="EUR">EUR</Radio.Button>
                <Radio.Button value="UAH">UAH</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item
              rules={[
                {
                  type: 'string',
                  min: 0,
                  max: 255,
                  message: t('manageForm.fields.description.validations.length')
                }
              ]}
              label={t('manageForm.fields.description.label')} name="description">
              <Input.TextArea/>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label={t('manageForm.fields.image.label')}
          name='imageType'
          className="text-align-center"
        >
          <Radio.Group style={{ width: '100%' }}>
            <Radio.Button value='profileImage'>
              {t('manageForm.fields.image.options.profile')}
            </Radio.Button>
            <Radio.Button value='customImage'>
              {t('manageForm.fields.image.options.custom')}
            </Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const imageType = getFieldValue('imageType');
            const fileList = getFieldValue('logo');

            if (imageType === 'profileImage') {
              return null;
            }

            return <Form.Item
              className="text-align-center"
              name="logo"
              valuePropName="fileList"
              rules={[
                {
                  required: true,
                  message: t('manageForm.fields.image.validations.required')
                }
              ]}
              getValueFromEvent={normFile}>
              <Upload
                customRequest={async (cb) => {
                  const { file, onSuccess, onError } = cb;

                  try {
                    const result = await profileHttp.upload(file);
                    onSuccess(result);

                    notification.info({
                      message: t('manageForm.fields.image.notifications.success')
                    });
                  } catch (error) {
                    onError(error);
                    notification.error({
                      message: t('manageForm.fields.image.notifications.error')
                    });
                  }
                }}
                listType="picture-card">
                {
                  fileList.length === 0
                    ? <button
                      style={{
                        border: 0,
                        background: 'none',
                        color: 'white'
                      }}
                      type="button"
                    >
                      <PlusOutlined/>
                      <div
                        style={{
                          marginTop: 8,
                        }}
                      >
                        {t('manageForm.fields.image.uploadTitle')}
                      </div>
                    </button>
                    : null
                }
              </Upload>
            </Form.Item>
          }}
        </Form.Item>

        <Form.Item className="text-align-right margin-bottom-10">
          <Button htmlType="submit" type="primary">{submitText}</Button>
        </Form.Item>
      </Form>
    </div>
  )
}
