import React from 'react';
import { useTranslation } from 'react-i18next';
import { notification, Button, Col, Grid, Input, Pagination, Row, Modal, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@tanstack/react-query';

import { useAuth } from '../../../providers/auth';

import Empty from '../../../components/Empty';
import Loading from '../../../components/Loading';

import JarCard from './components/JarCard';
import JarCreate from './components/JarManage/create';
import JarUpdate from './components/JarManage/update';
import JarWithdrawal from './components/JarWithdrawal';
import JarShareModal from './components/JarShare';
import http from './http';

export default function JarsPage () {
  const { t } = useTranslation('jars');
  const auth = useAuth();

  const screens = Grid.useBreakpoint();

  const [page, setPage] = React.useState(1);
  const [search, setSearch] = React.useState('');

  const [jarCreateId, setJarCreateId] = React.useState(null);
  const [jarUpdateId, setJarUpdateId] = React.useState(null);
  const [jarWithdrawal, setJarWithdrawal] = React.useState(null);
  const [jarShare, setJarShare] = React.useState(null);

  const jarsListQuery = useQuery({
    queryKey: ['jars', page, search],
    queryFn: () => http.list({
      page,
      search,
      status: 1
    }),
    refetchOnWindowFocus: false,
  });

  const mutation = useMutation({
    mutationFn: http.update,
    onSuccess: async () => {
      notification.success({
        message: t('archiveBlock.confirmMessage')
      });

      const { data } = await jarsListQuery.refetch();
      if (page !== 1 && data?.data.length === 0) {
        setPage(1);
      }
    },
    onError: (error) => {
      notification.error({
        message: error.message
      });
    }
  });

  const onSearch = (value) => {
    setSearch(() => value);
  };

  const pageChange = (value) => {
    setPage(() => value);
  };

  const onJarCreateClick = (id) => () => setJarCreateId(id);

  const onJarUpdateClick = (id) => () => setJarUpdateId(id);

  const onJarWithdrawalClick = (data) => () => setJarWithdrawal(data);

  const onJarArchiveClick = (data) => {
    const isArchived = data.status === 4;
    Modal.confirm({
      title: t('archiveBlock.confirmQuestion', {
        name: data.name,
        action: t(`${isArchived ? 'archiveBlock.unarchive' : 'archiveBlock.archive'}`)
      }),
      icon: <QuestionCircleOutlined />,
      okText: t(`${isArchived ? 'buttons.unarchive' : 'buttons.archive'}`),
      cancelText: t('buttons.cancel'),
      onOk: () => {
        return mutation.mutate({
          id: data.id,
          values: {
            status: isArchived ? 1 : 4
          }
        });
      },
      onCancel: () => {}
    })
  }

  const perPage = jarsListQuery.data?.meta.perPage || 1;
  const total = jarsListQuery.data?.meta.total || 0;

  const isCreateDisabled = auth.user.jarsAmountLimit <= total;

  return (
    <div id="dashboard-jars" className="dashboard">
      <Row justify="space-between" align="middle">
        <Col xs={8} sm={4} md={4} align="left">
          <div className="panel-title">{t('title')}</div>
        </Col>
        <Col xs={16} sm={10} md={10} align="center">
          <Input.Search placeholder={t('search.placeholder')} onSearch={onSearch}/>
        </Col>
        <Col xs={24} sm={4} md={4} align="right" className="create-button">
          <Tooltip placement="left" title={isCreateDisabled ? t('tooltips.jarsAmountLimit') : ''}>
            <Button disabled={isCreateDisabled} block={screens.xs} style={{ marginRight: 'auto' }} type="primary" onClick={onJarCreateClick(-1)}>
              {t('buttons.create')}
            </Button>
          </Tooltip>
        </Col>
      </Row>
      <div>
        {
          jarsListQuery.isSuccess && jarsListQuery.data?.data.length === 0
            ? (
              <Empty
                title={search ? t('search.404.title') : t('empty.title')}
                description={search ? t('search.404.desc') : t('empty.desc')}
              />
            )
            : null
        }
        {
          jarsListQuery.isFetching
            ? (
              <Loading />
            )
            : (jarsListQuery.data?.data || []).map((row) => {
              return <JarCard
                key={`jar-col-${row.uuid}`}
                data={row}
                onUpdateClick={onJarUpdateClick(row.id)}
                onWithdrawalClick={onJarWithdrawalClick(row)}
                onShareClick={() => setJarShare(row)}
                onArchiveClick={() => onJarArchiveClick(row)}
              />
            })
        }
      </div>
      {
        total > perPage ?
          <div style={{ textAlign: 'center', marginTop: '16px' }}>
            <Pagination
              current={page}
              onChange={pageChange}
              pageSize={perPage}
              total={total}
            />
          </div>
          : null
      }

      <JarCreate
        open={jarCreateId === -1}
        onClose={onJarCreateClick(null)}
      />
      <JarUpdate
        id={jarUpdateId}
        open={jarUpdateId !== null}
        onClose={onJarUpdateClick(null)}
      />
      <JarWithdrawal
        data={jarWithdrawal}
        open={jarWithdrawal !== null}
        onClose={onJarWithdrawalClick(null)}
      />
      <JarShareModal
        data={jarShare}
        open={jarShare !== null}
        onClose={() => setJarShare(null)}
      />
    </div>
  )
}
