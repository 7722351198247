import { Col, Layout, Row } from 'antd';
import { InstagramOutlined, TwitterOutlined, MailOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import config from '../../../../config';
import LanguageSelect from '../LanguageSelect';

export default function Footer () {
  const { t } = useTranslation('layout');

  return (
    <Layout.Footer id="cjaaar-footer">
      <Row justify="center" gutter={[14, 8]}>
        <Col xs={24} sm={2} className="column">
          <LanguageSelect/>
        </Col>
        <Col className="column">
          <a href={`${config.dashboardUrl}/terms`} target="_blank" rel="noreferrer">{t('footer.terms')}</a>
        </Col>
        <Col className="column">
          <a href={`${config.dashboardUrl}/privacy`} target="_blank" rel="noreferrer">{t('footer.privacy')}</a>
        </Col>
        <Col className="column">
          <a href="https://www.instagram.com/cjaaar.official" target="_blank" rel="noreferrer">
            <InstagramOutlined/>
          </a>
        </Col>
        <Col className="column">
          <a href="https://twitter.com/cryptojaaar" target="_blank" rel="noreferrer">
            <TwitterOutlined/>
          </a>
        </Col>
        <Col className="column">
          <a href="mailto:hello@cjaaar.com">
            <MailOutlined />
          </a>
        </Col>
        {/*<Col className="column">
          <a href={`${config.dashboardUrl}/contact-us`} target="_blank" rel="noreferrer">{t('footer.contactUs')}</a>
        </Col>*/}
        <Col className="column">
          <div>© {new Date().getFullYear()} CJAAAR</div>
        </Col>

      </Row>
    </Layout.Footer>
  )
}


