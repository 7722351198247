import React from 'react';
import { Col, Row, Avatar, Tag, Divider, Card, message, Tooltip, Pagination } from 'antd';
import {
  SyncOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

import * as utils from '../../../helpers/utils';
import Empty from '../../../components/Empty';
import Loading from '../../../components/Loading';

import logoImage from '../../../images/logo.png';
import http from './http';

const formatDate = (value) => {
  if (value < 10) {
    return `0${value}`;
  }
  return value;
}

export default function WithdrawalsPage () {
  const { t } = useTranslation('withdrawal');
  const [page, setPage] = React.useState(1);

  const listQuery = useQuery({
    queryKey: ['withdrawals', page],
    queryFn: () => http.list({
      page
    }),
    refetchOnWindowFocus: false,
  });

  const pageChange = (value) => {
    setPage(() => value);
  };

  const perPage = listQuery.data?.meta.perPage || 1;
  const total = listQuery.data?.meta.total || 0;

  return (
    <div id="dashboard-jars" className="dashboard">
      <Row justify="space-between" align="middle">
        <Col span={24} align="left">
          <div className="panel-title">{t('title')}</div>
        </Col>
      </Row>

      <div>
        {
          listQuery.isSuccess && listQuery.data?.data.length === 0
            ? (
              <Empty title={t('empty.title')} description={t('empty.desc')}/>
            )
            : null
        }
        {
          listQuery.isFetching
            ? (
              <Loading/>
            )
            : (listQuery.data?.data || []).map((row, key) => {
              return <WithdrawalItem key={`withdrawal-${key}`} data={row}/>
            })
        }
      </div>
      {
        total > perPage ?
          <div style={{ textAlign: 'center', marginTop: '16px' }}>
            <Pagination
              onChange={pageChange}
              pageSize={perPage}
              total={total}
            />
          </div>
          : null
      }
    </div>
  );
}

const RenderStatus = ({ status }) => {
  const { t } = useTranslation('withdrawal');
  if (status === 1) {
    return (
      <Tag icon={<SyncOutlined spin/>} color="processing">{t('statuses.pending')}</Tag>
    )
  }

  if (status === 2) {
    return (<Tag icon={<CheckCircleOutlined/>} color="success">{t('statuses.success')}</Tag>)
  }

  if (status === 3) {
    return (<Tag icon={<CloseCircleOutlined/>} color="error">{t('statuses.error')}</Tag>)
  }

  return (<Tag icon={<ClockCircleOutlined/>} color="default">{t('statuses.waiting')}</Tag>)
}

const WithdrawalItem = (props) => {
  const { t } = useTranslation('withdrawal');

  const { data } = props;
  const date = new Date(data.createdAt);
  const defaultCurrency = 'USD';

  let amountText = utils.formatCurrency(data.amountCurrency, data.amountDisplayed);
  let resultAmount = null;

  if (data.totalPayoutAmount !== null) {
    amountText = utils.formatCurrency(defaultCurrency, data.totalPayoutAmount);
    resultAmount = (data.totalPayoutAmount - data.totalPayoutFee).toFixed(2);
  } else if (data.amountRequested !== 0) {
    amountText = utils.formatCurrency(data.amountCurrency, data.amountRequested);
  }

  return (
    <Card size="small" bordered={false} className="margin-top-16 withdrawal-item">
      <Row align="middle">
        <Col xs={8} sm={4} md={3} lg={3} xl={3} xxl={3}
             className="text-align-center">
          <div className="display-full-center column">
            <div>{formatDate(date.getDate())}.{formatDate(date.getMonth())}.{date.getFullYear()}</div>
            <small>{formatDate(date.getHours())}:{formatDate(date.getMinutes())}</small>
          </div>
        </Col>
        <Col xs={16} sm={8} md={4} lg={4} xl={4} xxl={4} className="text-align-center">
          <Row align="middle">
            <Col span={8}>
              <div className="display-full-center">
                <Avatar className="fit-cover" style={{ backgroundColor: '#40325a', verticalAlign: 'middle' }}
                        size="large"
                        src={data.jar?.logo ? data.jar?.logo : logoImage}/>
              </div>
            </Col>
            <Col span={16}>
              <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{data.jar?.name}</div>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={0} md={0} lg={0} xl={0} xxl={0}>
          <Divider className="withdrawal-divider margin-top-mobile"/>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8} className="text-align-center">
          <Row align="middle" className="margin-top-mobile">
            <Col span={8}>
              <div className="withdrawal-label">{t('amount')}</div>
              <div className="jar-amount-collected">{amountText}</div>
            </Col>
            <Col span={8}>
              <div className="withdrawal-label">-&nbsp;{t('fee')}&nbsp;=</div>
              <div className="jar-amount-collected">
                {
                  data.totalPayoutFee !== null ?
                    utils.formatCurrency(defaultCurrency, data.totalPayoutFee)
                    : <SyncOutlined spin/>
                }
              </div>
            </Col>
            <Col span={8}>
              <div className="withdrawal-label">{t('total')}</div>
              <div className="jar-amount-target">
                {
                  resultAmount !== null ?
                    utils.formatCurrency(defaultCurrency, resultAmount)
                    : <ClockCircleOutlined/>
                }
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={12} sm={12} md={5} lg={5} xl={5} xxl={5}>
          <div className="divider">
            <Divider type="vertical" style={{ height: '100%' }}/>
          </div>
          <div className="text-align-center action-buttons">
            <div className="display-full-center column">
              <div className="withdrawal-label">{t('status')}:</div>
              <div>
                <RenderStatus status={data.status}/>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
          <CopyToClipboard
            text={data.uuid}
            onCopy={() => message.success('Copied')}
          >
            <Tooltip title={`Click to copy: ${data.uuid}`}>
              <div className="withdrawal-id">
                <div className="withdrawal-label text-align-center">{t('id')}</div>
                <div className="three-dots">{data.uuid}</div>
              </div>
            </Tooltip>
          </CopyToClipboard>
        </Col>
      </Row>
      <Row>
        <Divider className="withdrawal-divider margin-top-mobile"/>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
          <div className="withdrawal-details">
            <div className="withdrawal-label" style={{ paddingRight: '5px' }}>{t('asset')}:</div>
            <div>{data.amountAsset}</div>
          </div>
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
          <div className="withdrawal-details">
            <div className="withdrawal-label" style={{ paddingRight: '5px' }}>{t('network')}:</div>
            <div>{data.network}</div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
          <div className="withdrawal-details margin-top-mobile">
            <div className="withdrawal-label" style={{ paddingRight: '5px' }}>{t('address')}:</div>
            <div className="three-dots">{data.address}</div>
          </div>
        </Col>
      </Row>
    </Card>
  )
}
