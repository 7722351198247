import config from '../../config';
import { HTTP_CREDENTIALS, HTTP_HEADERS, HTTP_METHOD, HTTP_REDIRECT_FOLLOW } from '../../const';
import i18n from '../../i18n';

const whoami = async () => {
  const res = await fetch(new URL(`${config.webUrl}/auth/whoami`), {
    method: HTTP_METHOD.GET,
    headers: HTTP_HEADERS,
    credentials: HTTP_CREDENTIALS,
    redirect: HTTP_REDIRECT_FOLLOW
  });

  if (!res.ok) {
    throw new Error('Not authenticated');
  }

  const data = await res.json();

  /**
   * Change language if it's different from the default one
   */
  if (data.profile.language && i18n.language) {
    i18n.changeLanguage(data.profile.language);
  }

  return data;
};

const logout = async () => {
  const res = await fetch(new URL(`${config.webUrl}/auth/logout`), {
    method: HTTP_METHOD.POST,
    headers: HTTP_HEADERS,
    credentials: HTTP_CREDENTIALS,
  });

  if (!res.ok) {
    throw new Error('Logout failed');
  }

  return res.json();
};

const http = {
  whoami,
  logout
};

export default http;
