import Header from '../../../components/Layout/components/Header';
import { Col, Layout, Row } from "antd";
import Footer from '../../../components/Layout/components/Footer';
import { useTranslation } from "react-i18next";

export default function Terms () {
  const { t } = useTranslation('legal');
  const terms = t('terms', { returnObjects: true })
  return (
    <Layout id="cjaaar-layout">
      <Header/>
      <Layout.Content>
        <Row className="content-row">
          <Col md={0} lg={2} xl={4}></Col>
          <Col xs={24} sm={24} md={24} lg={20} xl={16} xxl={16}>
            <div className="panel-general">
              <div className="legal margin-bottom-25">
                <div className="panel-title margin-bottom-10">{terms.title}</div>
                <div className="subtext margin-bottom-25">{terms.updatedAt}</div>
                <div className="text">{terms.context}</div>
                {
                  terms.rules.map(((rule, key) => {
                    return (
                      <div key={`rule-${key}`} className="margin-top-25">
                        <div className="title">{rule.title}</div>
                        <div className="text">{rule.content}</div>
                        {
                          rule.list ?
                            <div className="text">
                              <ul>
                                {
                                  rule.list.map((list, key) => {
                                    return <li key={`list-${key}`}>{list}</li>
                                  })
                                }
                              </ul>
                            </div>
                            : null
                        }
                      </div>
                    )
                  }))
                }
              </div>
            </div>
          </Col>
          <Col md={0} lg={2} xl={4}></Col>
        </Row>
      </Layout.Content>
      <Footer/>
    </Layout>
  )
}
