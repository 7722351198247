import { Modal, Card, QRCode, Button, Divider, Typography, Space, message } from 'antd';
import { CopyOutlined, ExportOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

import { formatCurrency } from '../../../../../helpers/utils';
import config from '../../../../../config';
import logoImage from '../../../../../images/logo.png';

const generateShareLink = (uuid) => {
  return `${config.dashboardUrl}/jar/${uuid}`;
  // ?utm_source=cjaaar&utm_medium=dashboard&utm_campaign=review
};

function RenderShareData({ data }) {
  const { t } = useTranslation('jars');

  if (!data) return null;

  const [messageApi, contextHolder] = message.useMessage();

  const successCopied = (content) => {
    messageApi.open({
      type: 'success',
      content
    });
  };

  const shareLink = generateShareLink(data.uuid);
  const goal = formatCurrency(data.currency, data.amount)

  return (
    <div>
      {contextHolder}
      <div className="title">{t('share.title')}</div>
      <div className="jar-details margin-bottom-16">
        <div>{data.name}</div>
        <Divider style={{ color: '#a0a0a0' }} type="vertical" />
        <div>{t('share.goal')}: {goal}</div>
      </div>
      <Card size="small">
        <div className="details-title">{t('share.details')}</div>
        <div className="margin-bottom-16">
          <div className="text-align-center margin-bottom-10">
            <Typography.Text code>{shareLink}</Typography.Text>
          </div>
          <div className="text-align-center">
            <Space>
              <CopyToClipboard
                text={shareLink}
                onCopy={() => successCopied('Share link copied to clipboard')}
              >
                <Button><CopyOutlined /> {t('share.copyText')}</Button>
              </CopyToClipboard>

              <Button onClick={() => window.open(shareLink, '_blank')}><ExportOutlined /> {t('share.openText')}</Button>
            </Space>
          </div>
        </div>
        <div className="margin-bottom-16">
          <div className="label margin-bottom-10">{t('share.qrCodeText')}</div>
          <div className="qr-code">
            <QRCode
              errorLevel="H"
              size={180}
              value={shareLink}
              icon={logoImage}
              iconSize={32}
              color='#000'
              bgColor='#F7F7F7'
            ></QRCode>
          </div>
        </div>
        <div className="margin-bottom-10 text-align-center">
          <CopyToClipboard
            text={`${data.name}\n\n${data.description}\n\n${t('share.goal')}: ${goal}\n\n${t('share.shareText.linkToJar')}:\n${shareLink}`}
            onCopy={() => successCopied(t('share.shareText.message'))}
          >
            <Button type="primary">{t('share.shareText.copy')}</Button>
          </CopyToClipboard>
        </div>
      </Card>
    </div>
  );
}

export default function JarShare ({ data, open, onClose }) {
  return (
    <Modal
      className="cjaaar-modal"
      maskClosable={false}
      open={open}
      onCancel={() => onClose()}
      onOk={() => onClose()}
      footer={null}
    >
      <RenderShareData data={data} />
    </Modal>
  )
}
