import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { App, Modal, Spin } from 'antd';
import http from '../../http';

import ManageForm from './components/form';

const getInitialValues = (data) => {
  if (!data) return {};

  if (data.logo) {
    data.imageType = 'customImage';
    data.logo = [{
      uid: '-1',
      name: 'image.png',
      status: 'done',
      url: data.logo
    }];
  } else {
    data.imageType = 'profileImage';
    data.logo = [];
  }

  return data;
}

export default function JarUpdate (props) {
  const { id, open, onClose } = props;

  const { t } = useTranslation('jars');

  const { notification } = App.useApp();

  const queryClient = useQueryClient();

  const jarGetQuery = useQuery({
    queryKey: ['jar', 'update', id],
    queryFn: () => id ? http.get(id) : null,
    refetchOnWindowFocus: false,
  });

  const mutation = useMutation({
    mutationFn: http.update,
    onSuccess: () => {
      notification.success({
        message: t('update.notifications.success')
      });

      queryClient.invalidateQueries({ queryKey: ['jars'] });

      onClose();
    },
    onError: () => {
      notification.error({
        message: t('update.notifications.error')
      });
    }
  });

  const onFormSubmit = (values) => {
    const logo = values.imageType === 'customImage' ? values.logo[0]?.response?.url : '';
    mutation.mutate({
      id,
      values: {
        ...values,
        type: 1,
        logo
      }
    });
  };

  return (
    <Modal
      className="cjaaar-modal"
      destroyOnClose={true}
      open={open}
      onCancel={onClose}
      maskClosable={false}
      footer={null}
    >
      {
        jarGetQuery.isFetching ?
          <Spin style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', minHeight: '200px' }} />
          :
          <ManageForm
            initialValues={getInitialValues({ ...jarGetQuery.data })}
            onFormSubmit={onFormSubmit}
            title={t('update.title')}
            submitText={t('update.form.submitText')}
          />
      }
    </Modal>
  );
};
