import React from 'react';
import { Card, Progress, Row, Col, Tooltip, Avatar, Divider, Badge } from 'antd';
import {
  EditOutlined,
  PlayCircleOutlined,
  ShareAltOutlined, SnippetsOutlined, SyncOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { segmentTwoColors } from '../../../../../helpers/constants';
import * as utils from '../../../../../helpers/utils';
import logoImage from '../../../../../images/logo.png';

function FreeFeeWrapper ({ fee, children }) {
  const { t } = useTranslation('jars');
  if (fee !== 0) return children;

  return (
    <Badge.Ribbon placement='start' text={(<Tooltip title={t('tooltips.zeroProjectFee')}>0%</Tooltip>)} color="blue">
      {children}
    </Badge.Ribbon>
  )
}

export default function JarCard ({ data, onUpdateClick, onShareClick, onWithdrawalClick, onArchiveClick }) {
  const { t } = useTranslation();

  if (!data) return;

  const balance = utils.getBalance(data);
  const balanceProgress = utils.getBalanceProgress(data);
  const activeWithdrawals = data.withdrawals?.length;

  const ActionButtons = () => {
    return (
      <div className="action-buttons">
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', height: '100%' }}>
          <div onClick={onUpdateClick} className="list-button noselect active">
            <EditOutlined className="list-button-icon"/>
            <div className="list-button-text">{t('buttons.update', { ns: 'jars' })}</div>
          </div>
          <div onClick={onShareClick} className="list-button active noselect" style={{ color: '#138fe6' }}>
            <ShareAltOutlined className="list-button-icon" style={{ color: '#138fe6' }}/>
            <div className="list-button-text">{t('buttons.share', { ns: 'jars' })}</div>
          </div>
          <div onClick={balance && !activeWithdrawals ? onWithdrawalClick : null}
               className={`list-button noselect ${balance && !activeWithdrawals ? 'active' : 'disabled'}`}>
            {
              !activeWithdrawals ?
                <UploadOutlined className="list-button-icon"/>
                :
                <Tooltip title={t('tooltips.withdrawalInProgress', { ns: 'jars' })}>
                  <SyncOutlined className="list-button-icon" spin />
                </Tooltip>
            }

            <div className="list-button-text">{t('buttons.withdraw', { ns: 'jars' })}</div>
          </div>
          <Tooltip title={balance ? t('tooltips.archiveWithBalance', { ns: 'jars' }) : ''}>
            <div
              className={`list-button noselect ${balance ? 'disabled' : 'active'}`}
              onClick={!balance ? onArchiveClick : null}
            >
              <SnippetsOutlined className="list-button-icon" style={{ color: 'rgba(255, 255, 255, 0.4)' }}/>
              <div className="list-button-text">{t('buttons.archive', { ns: 'jars' })}</div>
            </div>
          </Tooltip>
        </div>
      </div>
    )
  }

  return (
    <FreeFeeWrapper fee={data.withdrawalFee}>
      <Card size="small" bordered={false} className="jar-card">
        <Row>
          <Col xs={4} sm={4} md={2} lg={2} xl={2} xxl={2}>
            <div className="avatar">
              <Avatar className="fit-cover" style={{ backgroundColor: '#40325a', verticalAlign: 'middle' }} size="large"
                      src={data.logo ? data.logo : logoImage}/>
            </div>
          </Col>
          <Col xs={20} sm={20} md={12} lg={12} xl={12} xxl={12}>
            <Row align="middle">
              <Col span={8}>
                <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{data.name}</div>
              </Col>
              <Col span={16}>
                <div style={{ textAlign: 'right', paddingRight: '35px' }}>
                  <span className="jar-amount-collected">{utils.formatCurrency(data.currency, balance)}</span>
                  <span className="jar-amount-of">{t('accumulatesOf', { ns: 'jar' })}</span>
                  <span
                    className="jar-amount-target">{utils.formatCurrency(data.currency, data.amount)}</span>
                </div>
              </Col>
            </Row>
            <div>
              <Progress size={['100%', 12]} showInfo={true} percent={balanceProgress}
                        strokeColor={segmentTwoColors} format={(percent) => `${Math.round(percent)}%`}/>
            </div>
          </Col>
          <Col xs={24} sm={24} md={10} lg={10} xl={10} xxl={10}>
            <div className="divider">
              <Divider type="vertical" style={{ height: '100%' }}/>
            </div>
            {
              data.status === 1
                ? <ActionButtons />
                :
                <div className="action-buttons" style={{ justifyContent: 'center' }}>
                  <div
                    className={`list-button noselect active`}
                    onClick={!balance ? onArchiveClick : null}
                  >
                    <PlayCircleOutlined className="list-button-icon" style={{ color: 'rgba(255, 255, 255, 0.4)' }}/>
                    <div className="list-button-text">{t('buttons.unarchive', { ns: 'jars' })}</div>
                  </div>
                </div>
            }
          </Col>
        </Row>
      </Card>
    </FreeFeeWrapper>
  );
}
