import config from '../../../config';
import { HTTP_HEADERS, HTTP_METHOD } from '../../../const';

const getJar = async (uuid) => {
    const res = await fetch(new URL(`${config.webUrl}/jars/${uuid}`), {
        method: HTTP_METHOD.GET,
        headers: HTTP_HEADERS,
    });

    if (res.ok) {
        return res.json();
    }

    return null;
};

const http = {
    getJar
};

export default http;
