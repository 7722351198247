import React from 'react';
import { Empty, Typography } from 'antd';

export default function EmptyComponent ({ title, description }) {
  return <Empty
    style={{
      marginTop: 100
    }}
    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
    imageStyle={{
      height: 60,
    }}
    description={
      <Typography.Title level={2}>{title}</Typography.Title>
    }
  >
    <Typography.Paragraph>{description}</Typography.Paragraph>
  </Empty>
}
