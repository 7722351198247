import React from 'react';
import { useNavigate } from 'react-router-dom';
import http from './http';
import { useMutation, useQuery } from '@tanstack/react-query';

const AuthContext = React.createContext(null);

export function AuthProvider({ children, ...props }) {
  const navigate = useNavigate();

  const query = useQuery({
    queryKey: ['whoami'],
    queryFn: async () => {
      try {
        const data = await http.whoami();

        return data;
      } catch (error) {
        // ... redirect to login not needed here
        if (props.redirectToLoginOnError) {
          return navigate('/login');
        }
        return null;
      }
    },
    refetchOnWindowFocus: false,
  })

  const logout = useMutation({
    mutationFn: async () => {
      try {
        await http.logout()

        window.location.href = '/';
      } catch (error) {
        // ..
      }
    },
    onSuccess: () => {
      // ...
    },
    onError: () => {
      // ...
    }
  })

  const value = {
    isLoading: query.isFetching,
    user: query.data,
    logout
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function RequireAuth({ children }) {
  const navigate = useNavigate();
  const auth = useAuth();

  if (auth.isLoading) {
    // TODO: add nice loader
    return 'Loading...';
  }

  if (!auth.user && !auth.isLoading) {
    navigate('/login');
  }

  return children;
}

export function useAuth() {
  return React.useContext(AuthContext);
}
