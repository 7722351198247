import React, { useState, Fragment } from 'react';
import { Menu, Layout, Avatar, Drawer } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { UploadOutlined, SettingOutlined, LogoutOutlined, BookOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n';
import { useAuth } from '../../../../providers/auth';
import { JarIcon } from '../../../Icons';

const getMenuItems = (auth) => {
  return [
    {
      type: 'divider'
    },
    /*{
      key: '/',
      icon: <DashboardOutlined />,
      label: i18n.t('sider.menu.dashboard'),
    },*/
    {
      key: '/app/jars',
      icon: <JarIcon/>,
      label: i18n.t('sider.menu.jars', { ns: 'layout' }),
    },
    {
      key: '/app/withdrawals',
      icon: <UploadOutlined/>,
      label: i18n.t('sider.menu.withdrawals', { ns: 'layout' }),
    },
    {
      key: '/app/address-book',
      icon: <BookOutlined />,
      label: i18n.t('sider.menu.addressBook', { ns: 'layout' }),
    },
    {
      key: '/app/settings',
      icon: <SettingOutlined/>,
      label: i18n.t('sider.menu.settings', { ns: 'layout' }),
      children: [
        {
          key: 'profile',
          label: i18n.t('sider.menu.settings/profile', { ns: 'layout' })
        },
        {
          key: 'security',
          label: i18n.t('sider.menu.settings/security', { ns: 'layout' })
        }
      ]
    },
    {
      type: 'divider'
    },
    {
      key: 'logout',
      icon: <LogoutOutlined/>,
      label: i18n.t('sider.menu.logout', { ns: 'layout' }),
      onClick: () => {
        auth.logout.mutate()
      },
    }
  ]
}

const getSelectedKeys = (items, location) => {
  if (location.pathname === '/') {
    return ['/'];
  }

  const selectedKeys = [];

  const pathname = location.pathname;

  const fn = (items) => {
    items.forEach((item) => {
      if (pathname.includes(item.key)) {
        selectedKeys.push(item.key);
      }
      if (item.children) {
        fn(item.children);
      }
    });
  }

  fn(items);

  return selectedKeys;
};

function SiderContent ({ onItemClicked = null }) {
  const { t } = useTranslation('layout');
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();

  const menuItems = getMenuItems(auth);
  const defaultKeys = getSelectedKeys(menuItems, location);

  return (
    <Fragment>
      <div className="hey-text">{t('sider.hey')},</div>
      <div className="avatar">
        {
          auth.user?.profile.avatarUrl ?
            <Avatar
              style={{ marginRight: '10px' }}
              size={30}
              src={auth.user?.profile.avatarUrl}
            />
            : null
        }
        <div style={{ fontSize: '16px' }}>{auth.user?.profile.firstName}</div>
      </div>

      <Menu
        style={{ border: 0 }}
        className="sider-menu transparent"
        onClick={({ keyPath, key }) => {
          if (key === 'logout') return;
          navigate(keyPath.reverse().join('/'));

          if (onItemClicked) {
            onItemClicked();
          }
        }}
        defaultSelectedKeys={defaultKeys}
        defaultOpenKeys={defaultKeys}
        mode="inline"
        items={menuItems}/>
    </Fragment>
  )
}

function MobileSider ({ showSidebar, setShowSidebar }) {
  return (
    <Drawer
      className="cjaaar-sider panel-light-dark"
      placement="left"
      open={showSidebar}
      onClose={() => setShowSidebar(!showSidebar)}
    >
      <SiderContent onItemClicked={() => setShowSidebar(false)} />
    </Drawer>
  );
}

function DesktopSider ({ setMobile }) {
  return (
    <div className="cjaaar-sider desktop panel-light-dark content-row full-height">
      <Layout.Sider
        className="transparent"
        collapsible={false}
        collapsedWidth="0"
        breakpoint="lg"
        trigger={null}
        onBreakpoint={isMobile => {
          setMobile(isMobile);
        }}
      >
        <SiderContent/>
      </Layout.Sider>
    </div>
  );
}

export default function Sider ({ showSidebar, setShowSidebar }) {
  const [mobileView, setMobile] = useState(false);

  return mobileView ? <MobileSider showSidebar={showSidebar} setShowSidebar={setShowSidebar} /> : <DesktopSider setMobile={setMobile}/>;
}
