import React, { useMemo, useRef, useState } from 'react';
import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import http from '../../http';
import { useTranslation } from 'react-i18next';

function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  );
}

function fetchUserAddresses(input) {
  return http.list({
      search: input
    })
    .then((body) =>
      body.data.map((row) => ({
        label: row.address,
        value: row.address,
      })),
    );
}

export const SearchInput = (props) => {
  const { t } = useTranslation('addressbook');

  return (
    <DebounceSelect
      showSearch
      placeholder={t('search.placeholder')}
      fetchOptions={fetchUserAddresses}
      onChange={(newValue) => {
        props.onChange(newValue);
      }}
      style={{
        width: '100%',
      }}
    />
  );
};

