import { App, Button, Col, Form, Input, Row, Select, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import PhoneInput from 'antd-phone-input';
import { useAuth } from '../../../../../providers/auth';
import profileHttp from '../../../../../http/profile';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import React from "react";

const normFile = (e) => Array.isArray(e) ? e : (e?.fileList || []);

export default function SettingsProfilePage() {
  const auth = useAuth();

  const queryClient = useQueryClient();

  const { notification } = App.useApp();

  const { t } = useTranslation('settings');

  const phoneValidator = (_, value) => {
    if (value.countryCode?.toString()[0] === '7' && value.isoCode !== 'kz') {
      return Promise.reject(t('profile.form.fields.phone.validations.required'));
    }

    if (value.valid(true)) return Promise.resolve();

    return Promise.reject(t('profile.form.fields.phone.validations.invalid'));
  }

  const mutation = useMutation({
    mutationFn: profileHttp.update,
    onSuccess: () => {
      notification.success({
        message: t('profile.notifications.success.title')
      });

      if (!mutation.variables.avatarUrl) {
        return;
      }

      queryClient.setQueryData(['whoami'], (old) => {
        return {
          ...old,
          profile: {
            ...old.profile,
            avatarUrl: mutation.variables.avatarUrl
          }
        }
      });
    },
    onError: () => {
      notification.error({
        message: t('profile.notifications.error.title')
      });
    }
  })

  return (
    <div>
      <div className="panel-title">{t('profile.title')}</div>

      <Form
        onFinish={(values) => {
          const avatarUrl = values.avatarUrl[ 0 ]?.response?.url;

          const phone = values.phone.phoneNumber
            ? String(values.phone.countryCode) + String(values.phone.areaCode) + String(values.phone.phoneNumber)
            : null;

          mutation.mutate({
            ...values,
            phone,
            avatarUrl
          });

          if (auth.user.profile.language !== values.language) {
            localStorage.setItem('lang', values.language);
            window.location.reload();
          }
        }}
        layout="vertical"
        initialValues={{
          email: auth.user.email,
          firstName: auth.user.profile.firstName,
          lastName: auth.user.profile.lastName,
          phone: auth.user.profile.phone,
          language: auth.user.profile.language,
          timezone: auth.user.profile.timezone,
          avatarUrl: auth.user?.profile.avatarUrl ? [{
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: auth.user?.profile.avatarUrl
          }] : []
        }}
      >
        <Row>
          <Col span={12} className="text-align-center">
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const fileList = getFieldValue('avatarUrl');

                return <Form.Item
                  label={t('profile.form.fields.avatar.label')}
                  name="avatarUrl"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}>
                  <Upload
                    customRequest={async (cb) => {
                      const { file, onSuccess, onError } = cb;

                      try {
                        const result = await profileHttp.upload(file);
                        onSuccess(result);

                        notification.info({
                          message: t('profile.form.fields.avatar.notifications.success.title'),
                          description: t('profile.form.fields.avatar.notifications.success.desc'),
                        });
                      } catch (error) {
                        onError(error);
                        notification.error({
                          message: t('profile.form.fields.avatar.notifications.error.title')
                        });
                      }
                    }}
                    listType="picture-card">
                    {
                      fileList.length === 0
                        ? <button
                          style={{
                            border: 0,
                            background: 'none',
                            color: 'white'
                          }}
                          type="button"
                        >
                          <PlusOutlined/>
                          <div
                            style={{
                              marginTop: 8,
                            }}
                          >
                            Upload
                          </div>
                        </button>
                        : null
                    }
                  </Upload>
                </Form.Item>
              }}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('profile.form.fields.email.label')} name="email">
              <Input disabled/>
            </Form.Item>
            <Form.Item label={t('profile.form.fields.phone.label')} name="phone"
                       rules={[{ validator: phoneValidator }]}>
              <PhoneInput
                excludeCountries={['ru', 'ir', 'iq', 'sy', 'by', 'kp']}
                enableSearch
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[10, 10]} justify="space-between">
          <Col span={12}>
            <Form.Item label={t('profile.form.fields.firstName.label')} name="firstName">
              <Input/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('profile.form.fields.lastName.label')} name="lastName">
              <Input/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[10, 10]} justify="space-between">
          <Col span={12}>
            <Form.Item label={t('profile.form.fields.language.label')} name="language">
              <Select
                options={[
                  { value: 'en', label: 'English' },
                  { value: 'ua', label: 'Ukrainian' },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('profile.form.fields.timezone.label')} name="timezone">
              <Select
                options={[
                  { value: 'UTC', label: 'UTC' },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item className="text-align-right">
          <Button type="primary" htmlType="submit">
            {t('profile.form.buttons.update')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
