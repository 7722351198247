import { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Layout, Row, Col, Spin } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import Header from '../../components/Layout/components/Header';
import Footer from '../../components/Layout/components/Footer';

import logoGreyImage from '../../images/logo_grey.png';

import http from './helpers/http';
import { LeftPanel, RightPanel } from './components/jar';

import './index.css';

export default function JarPage() {
    const { uuid } = useParams();
    const query = useQuery({
        queryKey: ['getJar'],
        queryFn: () => http.getJar(uuid),
        refetchOnWindowFocus: false,
    })

    return (
        <Layout id="cjaaar-layout">
            <Header />
            <Layout.Content>
                <Row className="content-row">
                    <Col md={0} lg={2} xl={4}></Col>
                    {
                        query.isFetching ?
                            <Col xs={24} sm={24} md={24} lg={20} xl={16} xxl={16}>
                                <div id="loader-panel" className="panel-general">
                                    <Spin size="large" className="panel-loader" />
                                </div>
                            </Col>
                            :
                            <LoadingContent query={query} />
                    }
                    <Col md={0} lg={2} xl={4}></Col>
                </Row>
            </Layout.Content>
            <Footer />
        </Layout>
    )
}

function LoadingContent({ query }) {
    const { t } = useTranslation('jar');
    const [token, setToken] = useState({ name: 'USDT', protocol: 'TRC20' });

    if (!query.data) {
        return (
            <Col xs={24} sm={24} md={24} lg={20} xl={16} xxl={16}>
                <div id="loader-panel" className="panel-general">
                    <div className="panel-not-found">
                        <div className="no-found-container">
                            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '100px', paddingBottom: '30px' }}>
                                <div className="no-found-text">{t('notFoundThis')} <img alt="jar was not found" style={{ height: '50px', padding: '0 10px' }} src={logoGreyImage} /> {t('notFoundJar')}</div>
                            </div>
                            <div style={{ fontSize: '18px' }}>{t('notFoundDesc')}</div>
                            <div style={{ fontSize: '18px' }}>{t('notFoundDesc2')}</div>
                        </div>
                        {/*<div className="helper-image">
                            <img style={{ width: '150px' }} src={helperImage} />
                        </div>*/}
                    </div>
                </div>
            </Col>
        );
    }

    if (query.data) {
        return (
            <Fragment>
                <LeftPanel
                    data={query.data}
                    token={token}
                    setToken={setToken}
                />
                <RightPanel
                    data={query.data}
                    token={token}
                />
            </Fragment>
        )
    }
}


