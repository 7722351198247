import { HTTP_CREDENTIALS, HTTP_HEADERS, HTTP_METHOD } from '../const';
import config from '../config';

const update = async (body) => {
  const res = await fetch(new URL(`${config.webUrl}/api/settings/profile`), {
    method: HTTP_METHOD.PUT,
    headers: HTTP_HEADERS,
    credentials: HTTP_CREDENTIALS,
    body: JSON.stringify(body)
  });

  if (!res.ok) {
    throw new Error('Update profile failed');
  }

  const json = await res.json();

  return json;
};

const upload = async (file) => {
  const fd = new FormData();

  fd.append('file', file);

  const res = await fetch(new URL(`${config.webUrl}/api/upload-image`), {
    method: HTTP_METHOD.POST,
    credentials: HTTP_CREDENTIALS,
    body: fd
  });

  if (!res.ok) {
    throw new Error('Upload avatar failed');
  }

  const json = await res.json();

  return json;
}

const client = {
  update,
  upload
};

export default client;
