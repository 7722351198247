/**
 * THEME
 */
export const COLOR = {
  PRIMARY: '#FF6600'
};

/**
 * FORM
 */
export const FORM_VERTICAL_LAYOUT = 'vertical';

/**
 * BLOCKCHAIN
 */
export const BLOCKCHAIN = {
  ETH: 'Ethereum',
  TRX: 'Tron'
};

export const BLOCKCHAIN_TOKENS = {
  [BLOCKCHAIN.ETH]: ['USDT'],
  [BLOCKCHAIN.TRX]: ['USDT']
};

export const BLOCKCHAIN_TOKENS_ADDRESS_REGEXP = {
  [BLOCKCHAIN.ETH]: /^0x[a-fA-F0-9]{40}$/g,
  [BLOCKCHAIN.TRX]: /T[a-zA-Z0-9]{33}/g
};

export const BLOCKCHAIN_TOKENS_ADDRESS_EXAMPLE = {
  [BLOCKCHAIN.ETH]: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  [BLOCKCHAIN.TRX]: 'TFASyvWdbGH2pgXmLhf57k4RxfrpnwWbpk'
};

/**
 * HTTP
 */
export const HTTP_METHOD = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE'
};

export const HTTP_CONTENT_TYPE = {
  JSON: 'application/json'
};

export const HTTP_CREDENTIALS = 'include';

export const HTTP_REDIRECT_FOLLOW = 'follow';

export const HTTP_HEADERS = {
  'Accept': 'application/json',
  'Content-Type': HTTP_CONTENT_TYPE.JSON
};

/**
 * UTILS
 */
export const moneyFormatter = (currency = 'USD', locale = 'en') => new Intl.NumberFormat(locale, {
  style: 'currency',
  currency,
  minimumFractionDigits: 2
});
